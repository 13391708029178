import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Stuff`}</h2>
    <ProjectCard title="Shorten.club" link="https://shorten.club/" bgImage="shortenclub.png" bgStartColor="#0a2d30" bgEndColor="#104449" mdxType="ProjectCard">
  URL shortener to voice for Clubhouse.
    </ProjectCard>
    <ProjectCard title="Copycat" link="https://github.com/omergulen/copycat" bgImage="copycat.png" bgStartColor="#202531" bgEndColor="#694CAA" mdxType="ProjectCard">
  Create a test code without writing a line of code!
    </ProjectCard>
    <ProjectCard title="Zoomblr" link="https://zoomblr.com/" bgImage="zoomblr.png" bgStartColor="#202531" bgEndColor="#3c75bf" mdxType="ProjectCard">
  Zoomblr helps you to view Tumblr avatars in high-quality.
    </ProjectCard>
    <ProjectCard title="Iytemenu" link="https://iytemenu.me/" bgImage="iytemenu.png" bgStartColor="#202531" bgEndColor="#bf3c3c" mdxType="ProjectCard">
  A webservice to organize every restaurant’s menu around IZTECH.
    </ProjectCard>
    <ProjectCard title="PH Trending Tab" link="https://github.com/omergulen/producthunt-trending-extension/" bgImage="ph.png" bgStartColor="#202531" bgEndColor="#A84B2E" mdxType="ProjectCard">
  View Product Hunt trending products on a new tab.
    </ProjectCard>
    <ProjectCard title="Universal Console Utils" link="https://github.com/omergulen/universal-console-utils" bgImage="vsc.png" bgStartColor="#202531" bgEndColor="#2E69A8" mdxType="ProjectCard">
  Ease inserting log (print) statements while coding on VSC.
    </ProjectCard>
    {
      /* <ProjectCard
       title="Copycat"
       link="https://github.com/omergulen/copycat"
       bgImage="copycat.png"
       bgStartColor="#202531"
       bgEndColor="#4CA1AA"
      >
       An extension to test web projects with ease! Create a test code without writing a line of code!
      </ProjectCard> */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      