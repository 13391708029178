import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I'm a `}<strong parentName="p">{`software engineer`}</strong>{` working as a `}<strong parentName="p">{`Fullstack Engineer`}</strong>{` `}<a parentName="p" {...{
        "href": "https://babbel.com"
      }}>{`@babbel`}</a>{`, and currently located in `}<strong parentName="p">{`Berlin, Germany`}</strong>{`.`}</p>
    <p>{`I have always loved automating, and building stuff that changes how we do things, and how people live.
I really enjoy solving challenging problems, over-engineering stuff and building products with people, and for the people.
Although I'm a software engineer, I have spent significant amount of time on designing products and preparing them for the market.`}</p>
    <p><strong parentName="p">{`So that, I can help you build or prepare your next idea from scratch for the market.`}</strong></p>
    <p>{`I've spent my time on various of subjects on these fields and I can help you with;`}</p>
    <ul>
      <li parentName="ul">{`Building a customized and performant website for your small business.`}</li>
      <li parentName="ul">{`Constructing an application to solve unique challenges.`}</li>
      <li parentName="ul">{`Improving your existing system by means of performance and product-wise.`}</li>
      <li parentName="ul">{`Providing a simple API for your application.`}</li>
      <li parentName="ul">{`Helping you with your resume, upcoming interview or presentation.`}</li>
    </ul>
    <p>{`This list isn't exhaustive. If you need help or just wanna meet, `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://superpeer.com/omer"
        }}>{`let's connect!`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      